@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Nekst", "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1a1120;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input,
select,
button {
  outline: none;
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card-text p {
  margin-top: 10px;
}
.card-text i {
  font-weight: 700;
  color: #44009A;
  font-style: normal;
}

.card-text em {
  font-weight: 700;
  font-style: normal;
}

.card-text ul {
  padding-left: 15px;
  margin-left: 15px;
  list-style-type: disc;
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-text ol {
  padding-left: 15px;
  margin-left: 15px;
  list-style-type: decimal;
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-text h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0;
}

.card-text h4 {
  font-size: 18px;
  margin: 10px 0; 
}

.card-text button {
  background-color: #7574cf;
  min-height: 47px;
  font-weight: 500;
  font-size: 18px;
  flex: 1 1 0;
  color: #ffffff;
  border-radius: 9999px;
  padding: 5px 15px;
  min-width: 120px;
}

.card-text table {
  border-collapse: collapse;
  border: 1px solid #afafaf;
  margin-bottom: 15px;
  margin-top: 15px;
  width: 100%;
}

.card-text td,
.card-text th {
  padding: 8px;
  border: 1px solid #afafaf;
}

.card-text td p,
.card-text th p {
  text-indent: 0;
  text-align: start;
  margin-bottom: 0;
}


.activity-indicator div {
  animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.activity-indicator div:nth-child(1) {
  animation-delay: -0.45s;
}
.activity-indicator div:nth-child(2) {
  animation-delay: -0.3s;
}
.activity-indicator div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Nekst";
  src: url("./assets/fonts/Nekst-Black.ttf");
  src:
    local("Nekst Black"),
    local("Nekst-Black"),
    url("./assets/fonts/Nekst-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nekst";
  src: url("./assets/fonts/Nekst-Medium.ttf");
  src:
    local("Nekst Medium"),
    local("Nekst-Medium"),
    url("./assets/fonts/Nekst-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nekst";
  src: url("./assets/fonts/Nekst-Bold.ttf");
  src:
    local("Nekst Bold"),
    local("Nekst-Bold"),
    url("./assets/fonts/Nekst-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nekst";
  src: url("./assets/fonts/Nekst-Regular.ttf");
  src:
    local("Nekst Regular"),
    local("Nekst-Regular"),
    url("./assets/fonts/Nekst-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nekst";
  src: url("./assets/fonts/Nekst-Light.ttf");
  src:
    local("Nekst Light"),
    local("Nekst-Light"),
    url("./assets/fonts/Nekst-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Black.ttf");
  src:
    local("Inter Black"),
    local("Inter-Black"),
    url("./assets/fonts/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Medium.ttf");
  src:
    local("Inter Medium"),
    local("Inter-Medium"),
    url("./assets/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Bold.ttf");
  src:
    local("Inter Bold"),
    local("Inter-Bold"),
    url("./assets/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Regular.ttf");
  src:
    local("Inter Regular"),
    local("Inter-Regular"),
    url("./assets/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Light.ttf");
  src:
    local("Inter Light"),
    local("Inter-Light"),
    url("./assets/fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
